<template>
	<div class="wrapper" v-if="$store.getters.authenticated">
		<Header></Header>
		<Menu></Menu>
		<router-view/>
		<Footer></Footer>
	</div>
	<div class="wrapper" v-else>
		<router-view/>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Menu from '@/components/Menu.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		name: 'App',
		components: { Header, Menu, Footer },
		created() {
			this.$store.dispatch('autoLogin');
		}
	}
</script>