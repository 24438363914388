<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <i class="fa fa-home"></i>
                    Dashboard
                </li>
                <li class="breadcrumb-item active">Sync Data</li>
               
            </ol>
        </nav>
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-header">
                                <strong>Modules</strong>
                            </div>
                            <div class="card-body">
                                <div class="row mb-2 text-center">
                                    <div class="col-6">
                                        <button class="btn btn-block btn-success" type="button" @click="syncState('No')">State</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-block btn-success" type="button" @click="syncCategories('No')">Category</button>
                                    </div>
                                </div>
                                <div class="row mb-2 text-center">
                                    <div class="col-6">
                                        <button class="btn btn-block btn-success" type="button" @click="syncCities('No')">City</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-block btn-success" type="button" @click="syncPacks('No')">Pack</button>
                                    </div>
                                </div>
                                <div class="row mb-2 text-center">
                                    <div class="col-6">
                                        <button class="btn btn-block btn-success" type="button" @click="syncAreas('No')">Area</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-block btn-success" type="button" @click="syncProducts('No')">Products</button>
                                    </div>
                                </div>

                                <div class="row mb-2 text-center">
                                    <div class="col-6">
                                        <button class="btn btn-block btn-success" type="button" @click="syncCustomers('No')">Customer</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-block btn-success" type="button" @click="syncInventories('No')">Inventory</button>
                                    </div>
                                </div>

                                <div class="row mb-2 text-center">
                                    <div class="col-6">
                                        <button class="btn btn-block btn-success" type="button" @click="syncManufacturers('No')">Manufacturer</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-block btn-success" type="button" @click="syncOutstandings('No')">OutStanding</button>
                                    </div>
                                </div>

                                <div class="row mb-2 text-center">
                                    <div class="col-12">
                                        <button class="btn btn-block btn-dark" type="button" @click="syncState('Yes')">All</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-header">
                                <strong>Logs</strong>
                            </div>
                            <div class="card-body" >
                            
                              <div  v-if="errors" style="color:red;">{{errors.message}}
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Sync",
      
        data() {
            return {
                states:[],
                categories:[],
                cities:[],
                packs:[],
                area:[],
                products:[],
                customers:[],
                inventories:[],
                manufacturers:[],
                oustandings:[],
                errors:""
            };
        },

        methods: {
            syncState(all) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "syncStates" };
                vm.$store.dispatch("get", uri)
                .then(function (response) {
                    loader.hide();
                    vm.states = response.data.data;
                    vm.$store.dispatch("success", "State is succesfully synced");
                    if(all=='Yes') {
                        vm.syncCities('Yes')
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    if(all=='Yes') {
                        vm.syncCities('Yes')
                    }
                });
            },

            syncCities(all) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "syncCities" };
                vm.$store.dispatch("get", uri)
                .then(function (response) {
                    loader.hide();
                    vm.cities = response.data.data;
                    vm.$store.dispatch("success", "City is succesfully synced");
                    if(all=='Yes') {
                        vm.syncAreas('Yes')
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    if(all=='Yes') {
                        vm.syncAreas('Yes')
                    }
                });
            },

            syncAreas(all) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "syncAreas" };
                vm.$store.dispatch("get", uri)
                .then(function (response) {
                    loader.hide();
                    vm.areas = response.data.data;
                    vm.$store.dispatch("success", "Area is succesfully synced");
                    if(all=='Yes') {
                        vm.syncCustomers('Yes')
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    if(all=='Yes') {
                        vm.syncCustomers('Yes')
                    }
                });
            },

            syncCustomers(all) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "syncCustomers" };
                vm.$store.dispatch("get", uri)
                .then(function (response) {
                    loader.hide();
                    vm.customers = response.data.data;
                    vm.$store.dispatch("success", "Customer is succesfully synced");
                    if(all=='Yes') {
                        vm.syncManufacturers('Yes')
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    if(all=='Yes') {
                        vm.syncManufacturers('Yes')
                    }
                });
            },

            syncManufacturers(all){
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "syncManufacturers" };
                vm.$store.dispatch("get", uri)
                .then(function (response) {
                    loader.hide();
                    vm.manufacturers = response.data.data;
                    vm.$store.dispatch("success", "Manufacturer is succesfully synced");
                    if(all=='Yes') {
                        vm.syncCategories('Yes')
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    if(all=='Yes') {
                        vm.syncCategories('Yes')
                    }
                });
            },

            syncCategories(all) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "syncCategories" };
                vm.$store.dispatch("get", uri)
                .then(function (response) {
                    loader.hide();
                    vm.categories = response.data.data;
                    vm.$store.dispatch("success", "Category is succesfully synced");
                    if(all=='Yes') {
                        vm.syncPacks('Yes')
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    if(all=='Yes') {
                        vm.syncPacks('Yes')
                    }
                });
            },

            syncPacks(all) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "syncPacks" };
                vm.$store.dispatch("get", uri)
                .then(function (response) {
                    loader.hide();
                    vm.packs = response.data.data;
                    vm.$store.dispatch("success", "Pack is succesfully synced");
                    if(all=='Yes') {
                        vm.syncProducts('Yes')
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    if(all=='Yes') {
                        vm.syncProducts('Yes')
                    }
                });
            },

            syncProducts(all) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "syncProducts" };
                vm.$store.dispatch("get", uri)
                .then(function (response) {
                    loader.hide();
                    vm.products = response.data.data;
                    vm.$store.dispatch("success", "Product is succesfully synced");
                    if(all=='Yes') {
                        vm.syncInventories('Yes')
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    if(all=='Yes') {
                        vm.syncInventories('Yes')
                    }
                });
            },

            syncInventories(all) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "syncInventories" };
                vm.$store.dispatch("get", uri)
                .then(function (response) {
                     loader.hide();
                    vm.inventories = response.data.data;
                    vm.$store.dispatch("success", "Inventory is succesfully synced");
                    if(all=='Yes') {
                        vm.syncOutstandings('Yes')
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    if(all=='Yes') {
                        vm.syncOutstandings('Yes')
                    }
                });
            },

            syncOutstandings(all){
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "syncOutstandings" };
                vm.$store.dispatch("get", uri)
                .then(function (response) {
                    loader.hide();
                    vm.outstandings = response.data.data;
                    vm.$store.dispatch("success", "Outstanding is succesfully synced");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                });
                
                console.log(all);
            },
        },
    };
</script>