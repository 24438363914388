<template>
    <div :class="[isOpen ? 'customer customer-open' : 'customer']">
        <div class="customer-inner">
            <input class="form-control form-control-sm customer-selected" 
                ref="toggle" 
                :tabindex="tabindex"
                :value="selectedText"
                :class="customClass"
                @click="onToggle"
                @keydown="onKey">
            <transition name="fade" mode="out-in">
                <div class="customer-dropdown" v-if="isOpen">
                    <div class="customer-input_wrap">
                        <input type="text" class="form-control form-control-sm customer-input" autocomplete="off" placeholder="Search..."
                            ref="search" @blur="onBlur"
                            @input="onSearch"
                            @keydown.esc="onEsc"
                            @keydown.up="onUpKey"
                            @keydown.down="onDownKey"
                            @keydown.enter="onEnterKey">
                    </div>
                    <table class="table table-responsive-sm table-bordered table-striped table-sm customer-list" v-if="results.length">
                        <thead>
                            <tr>
                                <th class="customer-link">Customer Name</th>
                                <th class="customer-link">Mobile No</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="customer-item" v-for="(result, index) in results" @mousedown.prevent="select(result)" @mouseover.prevent="onMouse(index)" :class="['customer-link', selectIndex === index ? 'customer-active':'']" :key="index">
                                <td>
                                    {{ result.customer_name }}
                                </td>
                                <td>
                                    {{ result.mobile_no }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </transition>
        </div>
    </div>
</template>

<style>
    .customer {
      position: relative;
      display: block;
      background: #fff;
    }

    .customer-open {
      border-bottom: 0;
    }

    .customer-open .form-control {
        background: #fff;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
        border: 1px solid #f2f2f2;
    }

    .customer-inner {
        position: relative;
    }

    .customer-selected {
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        line-height: 15px;
    }

    .customer-dropdown {
      column-width: 100%;
      position: absolute;
      z-index: 999 !important;
      padding: 5px;
      background: #fff;
      border-right: 1px solid #f2f2f2;
      border-left: 1px solid #f2f2f2;
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    }

    .customer-input {
      line-height: 13px;
      font-size: 13px;
      background: #fafafa;
      border: none;
      border-radius: 1px;
      -webkit-box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
              box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
      padding: 4px 8px;
      width: 100%;
      display: block;
    }

    .customer-input:focus {
      outline-style: dotted;
      outline-width: 1px;
      outline-offset: 1px;
    }

    .customer-input_wrap {
      position: relative;
    }

    .customer-list {
      display: block;
      margin: 0;
      padding: 0;
    }

    .customer-link {
      cursor: pointer;
      padding: 2px;
      background: #fff;
    }

    .customer-active {
      background: #3aa3e3 !important;
      color: #fff;
    }

    .customer-list td, th { 
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow:ellipsis; 
    }
</style>

<script type="text/javascript">
    import axios from 'axios';
    export default {
        props: {
            initialize: {
                default: null
            },
            customClass: {
                default: ''
            },
            tabindex: {
                default: 0
            },
        },
        data () {
            return {
                selectIndex: -1,
                isOpen: false,
                search: '',
                results: [],
                query: {
                    search: ''
                },
                temp:[],
            }
        },
        computed: {
            selectedText() {
                return this.initialize 
                    ? this.initialize
                    : 'Type or click to select customer'
            }
        },
        methods: {
            focus: function () {
                this.$refs.toggle.focus()
            },

            onToggle() {
                if(this.isOpen) {
                    this.isOpen = false
                } else {
                    this.open()
                }
            },
            onKey(e) {
                const KeyCode = e.KeyCode || e.which
                if(!e.shiftKey && KeyCode !== 9 && !this.isOpen) {
                    this.open()
                }
            },
            open() {
                this.fetchData('')
                this.isOpen = true
                this.$nextTick(() => {
                    this.$refs.search.focus()
                })
            },
            fetchData(query) {
                let vm = this;
                vm.results = [];
                vm.query.search = query;
                axios.get(this.$store.getters.apiUrl+'get_customers?search='+vm.query.search)
                .then(function (response) {
                    vm.results = response.data;
                });
            },
            onBlur() {
                this.close()
            },
            onEsc() {
                this.close()
            },
            close() {
                this.results = []
                this.isOpen = false
                this.search = ''
                this.selectIndex = -1
            },
            onSearch(e) {
                const q = e.target.value
                this.selectIndex = 0
                this.fetchData(q)
            },
            onUpKey(e) {
                console.log(e);
                if(this.selectIndex > 0) {
                    this.selectIndex--
                }
            },
            onDownKey(e) {
                console.log(e);
                if(this.results.length - 1 > this.selectIndex) {
                    this.selectIndex++
                }
            },
            onEnterKey() {
                const found = this.results[this.selectIndex]
                if(found) {
                    this.select(found)
                }
            },
            select(result) {
                this.$emit('input', {
                    target: {
                        value: result
                    }
                })
                this.close()
            },
            onMouse(index) {
                this.selectIndex = index
            }
         }
    }
</script>