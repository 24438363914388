<template>
	<div class="auth">
		<div class="col-xl-8 col-11">
			<div class="card auth-bg">
				<div class="row">
					<div class="col-md-6 col-12">
						<div class="card h-100 d-flex justify-content-center">
							<div class="card-body">
								<div class="heading">
									<div class="heading-text">
										Forgot Password ?
									</div>
								</div>
								<form @submit.prevent="forgotPassword">
									<div class="form-group">
										<label class="form-label">Email</label>
										<input type="text" class="form-control" :class="{'is-invalid': errors.email}" placeholder="Email" v-model="user.email" ref="email">
										<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
									</div>

									<button type="submit" class="btn btn-primary w-100 position-relative">
										Continue
										<i class="fa fa-arrow-right"></i>
									</button>

									<div class="text-center mt-2">
										<small>Already have account ? </small>
										<router-link to="/login">
											<small>Login Here</small>
										</router-link>
									</div>
								</form>
							</div>
						</div> 
					</div>
					<div class="col-md-6 d-md-block d-none">
						<div class="card-body">
							<img class="img-fluid" src="@/assets/branding.png" alt="Branding">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Login',
		data(){
			return{
				user:{
					email:'',
					password:''
				},
				errors:[]
			}
		},
		mounted(){
			this.$refs.email.focus();
		},
		methods:{
            forgotPassword() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('auth',{'uri':'forgot_password','data':vm.user})
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','New Password has been emailed to you, Please click on that.');
					vm.$router.push('/login');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
            }
		}
	}
</script>