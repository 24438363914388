<template>
	<nav class="main-header navbar navbar-expand navbar-white navbar-light">
		<!-- Left navbar links -->
		<ul class="navbar-nav">
			<li class="nav-item">
				<a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
			</li>
			<li class="nav-item d-none d-sm-inline-block">
                <a href="#" class="nav-link" style="font-size: 25px; margin-top: -7px">
					Chinnu Pharmaceuticals
				</a>
            </li>
		</ul>

		<ul class="navbar-nav ml-auto">
			<li class="nav-item">
				<router-link to="/profile" class="nav-link">
					<i class="fas fa-user"></i> Profile
				</router-link>
			</li>
			<li class="nav-item">
				<a class="nav-link" href="#" @click.prevent="logout()">
					<i class="fas fa-sign-out-alt"></i> Logout
				</a>
			</li>
		</ul>

	</nav>

</template>
<script>
	export default {
		name: 'Header',
		methods:{
			logout() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('post',{'uri':'logout','data':vm.$store.getters.user})
				.then(function () {
					loader.hide();
					vm.$store.dispatch('logout');
					vm.$router.push('/login');
					location.reload();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
            }
		}
	}
</script>