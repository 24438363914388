<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<i class="fa fa-home"></i>
					Dashboard
				</li>
				<li class="breadcrumb-item active">Users</li>
                <li class="breadcrumb-right">
                    <router-link to="/users" class="btn btn-sm btn-success">
                        <i class="fa fa-list"></i>
                        Users
                    </router-link>
                </li>
			</ol>
		</nav>
		<div class="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
                                <strong v-if="status">New User</strong>
                                <strong v-else>Update User</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Role *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.role}" placeholder="role" v-model="user.role">
												<option value="">Select Role</option>
												<option>Super Admin</option>
												<option>Sales Representative</option>
												<option>Customer</option>
											</select>
											<span v-if="errors.role" class="invalid-feedback">{{ errors.role[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Customer *</label>
											<Customer :class="{'is-invalid': errors.customer_id}" :customClass="{'is-invalid': errors.customer_id}" :initialize="user.customer_name" @input="selectCustomer($event)" ref="customer_id"></Customer>
											<span v-if="errors.customer_id" class="invalid-feedback">{{ errors.customer_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Name *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.name}" placeholder="Name" v-model="user.name" ref="name">
											<span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Username *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.username}" placeholder="Username" v-model="user.username">
											<span v-if="errors.username" class="invalid-feedback">{{ errors.username[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Email Address *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="user.email">
											<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Mobile No *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile No" v-model="user.mobile_no">
											<span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
										</div>
									</div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/users" class="btn btn-sm btn-outline-danger" type="button">
                                        <i class="fas fa-ban"></i> Discard
                                    </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import Customer from '@/components/Customer.vue'
	export default {
		name: 'User.Create',
		components: { Customer },
		data(){
            return{
                status:true,
                user:{
					user_id:'',
					customer_id:'',
					customer_name:'',
					name:'',
					username:'',
					email:'',
					mobile_no:'',
					role:'',
				},
				users:[],
				errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				if(to.name=='Users.Create') {
					vm.$refs.name.focus();
				}
				else {
					let uri = {'uri':'users/'+to.params.user_id};
					vm.$store.dispatch('get',uri)
					.then(function (response) {
						vm.user = response.data.data;
						if(vm.user.customer) {
							vm.user.customer_name = vm.user.customer.customer_name 
						}
						vm.status = false;
						vm.$refs.name.focus()
					})
					.catch(function (error) {
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			});
        },
		methods:{

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			selectCustomer(e){
				let vm = this;
				const customer = e.target.value;
				vm.user.customer_id = customer.customer_id;
				vm.user.customer_name = customer.customer_name;
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'users','data':vm.user};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','User is successfully created');
					vm.$router.push('/users');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(user){
                let vm = this;
                vm.user = user,
                vm.status = false,
                vm.$refs.user.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'users/'+vm.user.user_id,'data':vm.user};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','User is successfully updated');
					vm.$router.push('/users');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            }
		}
	}
</script>
