<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <a href="#" class="brand-link">
            <img src="../assets/logo.png" alt="Swasahaya" class="brand-image img-circle" />
            <span class="brand-text font-weight-light">Sales Order</span>
        </a>

        <!-- Sidebar -->
        <div class="sidebar">
            <!-- Sidebar user panel (optional) -->
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <div class="image">
                    <img src="../assets/avatar.svg" class="img-circle elevation-2" alt="User Image" />
                </div>
                <div class="info">
                    <a href="#" class="d-block">{{ $store.getters.user.name }}</a>
                </div>
            </div>

            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item">
                        <router-link to="/dashboard" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt blue"></i>
                            <p>Dashboard</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/users" class="nav-link">
                            <i class="nav-icon fas fa-users orange"></i>
                            <p>Users</p>
                        </router-link>
                    </li>
                     <li class="nav-item">
                        <router-link to="/sync" class="nav-link">
                            <i class="nav-icon fas fa-users purple"></i>
                            <p>Sync Data</p>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </aside>

</template>
<script>
	export default {
		name: 'Menu'
	}
</script>