import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store/index.js';

import Login from '@/views/auth/Login.vue'
import Forgot from '@/views/auth/Forgot.vue'

import Dashboard from '@/views/Dashboard.vue'
import ShowOrder from '@/views/ShowOrder.vue'
import Profile from '@/views/Profile.vue'

import Users from '@/views/user/Index.vue'
import CreateUser from '@/views/user/Create.vue'


import Sync from '@/views/Sync.vue'


const routes = [

	//Auth
	{ 
		path: '/login', name: 'Login', 
		component: Login,  meta: { unAuth: true } 
	},

	{ 
		path: '/forgot-password', name: 'Forgot', 
		component: Forgot,  meta: { unAuth: true } 
	},

	//Dashboard
	{ 
		path: '/', name: 'Home', 
		component: Dashboard,  meta: { auth: true } 
	},
	{ 
		path: '/dashboard', name: 'Dashboard', 
		component: Dashboard,  meta: { auth: true } 
	},
	{ 
		path: '/orders/:order_id', name:'Order.Show', 
		component:ShowOrder,  meta: { auth: true }
	},
	{ 
		path: '/profile', name: 'Profile', 
		component: Profile,  meta: { auth: true } 
	},

	//Users
	{ 
		path: '/users', name: 'Users.Index', 
		component: Users,  meta: { auth: true } 
	},
	{ 
		path: '/users/create', name: 'Users.Create', 
		component: CreateUser,  meta: { auth: true } 
	},
	{ 
		path: '/users/:user_id/edit', name:'Users.Edit', 
		component:CreateUser,  meta: { auth: true }
	},

	//sync
	{ 
		path: '/sync', 
		name:'Sync', 
		component:Sync,
		meta: { auth: true }
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach(function(to, _, next) {
	if (to.meta.auth && !store.getters.user) {
		next('/login');
	} 
	else if(to.meta.unAuth && store.getters.user) {
		next('/dashboard')
	}
	else {
		next();
	}
});

export default router
