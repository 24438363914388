<template>
	<div class="auth">
		<div class="col-xl-8 col-11">
			<div class="card auth-bg">
				<div class="row">
					<div class="col-md-6 col-12">
						<div class="card h-100 d-flex justify-content-center">
							<div class="card-body">
								<div class="heading">
									<div class="heading-text">
										Chinnu Pharmaceuticals
									</div>
								</div>
								<form @submit.prevent="login">
									<div class="form-group">
										<label class="form-label">Username</label>
										<input type="text" class="form-control" :class="{'is-invalid': errors.username}" placeholder="Username" v-model="user.username" ref="username">
										<span v-if="errors.username" class="invalid-feedback">{{ errors.username[0] }}</span>
									</div>

									<div class="form-group">
										<label class="form-label">Password</label>
										<input type="password" class="form-control" :class="{'is-invalid': errors.password}" placeholder="Password" v-model="user.password">
										<span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
									</div>

									<div class="form-group d-flex flex-md-row flex-column justify-content-between align-items-center">
										<div class="text-left">
											<div class="checkbox checkbox-sm">
												<input type="checkbox" class="form-check-input" id="keep_me_logged_in">
												<label class="checkboxsmall" for="keep_me_logged_in">
													<small>Keep me logged in</small>
												</label>
											</div>
										</div>
										<!-- <div class="text-right">
											<router-link to="/forgot-password">
												<small>Forgot Password?</small>
											</router-link>
										</div> -->
									</div>

									<button type="submit" class="btn btn-primary w-100 position-relative" :disabled="$store.getters.loading">
										Login
										<i class="fa fa-arrow-right"></i>
									</button>
								</form>
							</div>
						</div> 
					</div>
					<div class="col-md-6 d-md-block d-none">
						<div class="card-body">
							<img style="margin-top:20px" class="img-fluid" src="@/assets/logo.png" alt="Branding">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Login',
		data(){
			return{
				user:{
					username:'',
					password:'',
					role:'Super Admin'
				},
				errors:[]
			}
		},
		mounted(){
			this.$refs.username.focus();
		},
		methods:{
            login() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('auth',{'uri':'login','data':vm.user})
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch('success','Successfuly logged in');
					vm.$store.dispatch('setUser',response.data);
					vm.$router.push('/');
					location.reload();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
            }
		}
	}
</script>