<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<i class="fa fa-home"></i>
					Dashboard
				</li>
				<li class="breadcrumb-item active">Orders</li>
                <li class="breadcrumb-right">
                    <router-link to="/dashboard" class="btn btn-sm btn-secondary">
                        <i class="fa fa-arrow-left"></i>
                        Back
                    </router-link>
                    <button @click="destroy()" class="btn btn-sm btn-danger" style="margin-left:5px">
                        <i class="fa fa-trash"></i>
                        Delete
                    </button>
                </li>
			</ol>
		</nav>
		<div class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <div class="card card-widget widget-user">
                            <div class="widget-user-header bg-purple">
                                <h3 class="widget-user-username">
                                    {{ order.order_no }}
                                </h3>
                                <h5 class="widget-user-desc">
                                    {{ order.order_date }}
                                </h5>
                            </div>
                            <div class="widget-user-image">
                                <img class="img-circle elevation-2" src="../assets/logo.png" alt="Image">
                            </div>
                            <div class="card-body post" style="padding-top: 50px;">
                                <div class="user-block" v-if="order.customer">
                                    <img class="img-circle img-bordered-sm" src="../assets/icon.webp" alt="image">
                                    <span class="username">
                                        {{ order.customer.customer_name }}
                                    </span>
                                    <span class="description">
                                        Customer Name
                                    </span>
                                </div>
                                <div class="user-block" v-if="order.customer">
                                    <img class="img-circle img-bordered-sm" src="../assets/icon.webp" alt="image">
                                    <span class="username">
                                        {{ order.customer.mobile_no }}
                                    </span>
                                    <span class="description">
                                        Customer Mobile No
                                    </span>
                                </div>
                                <div class="user-block" v-if="order.user">
                                    <img class="img-circle img-bordered-sm" src="../assets/icon.webp" alt="image">
                                    <span class="username">
                                        {{ order.user.name }}
                                    </span>
                                    <span class="description">
                                       Booked By
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <strong>Ordered Products</strong>
                            </div>
                            <div class="card-body">
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Product Name</th>
											<th class="text-right">MRP</th>
											<th class="text-right">Rate</th>
                                            <th class="text-right">Qty</th>
                                            <th class="text-right">F Qty</th>
                                            <th class="text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(order_product,key) in order.order_products" :key="key">
                                            <td class="text-center">{{ key+1 }}</td>
                                            <td>{{ order_product.product.prod_name }}</td>
											<td class="text-right">{{ order_product.mrp }}</td>
											<td class="text-right">{{ order_product.rate }}</td>
                                            <td class="text-right">{{ order_product.quantity }}</td>
                                            <td class="text-right">{{ order_product.free_quantity }}</td>
                                            <td class="text-right">{{ (order_product.rate * order_product.quantity).toFixed(2) }}</td>
                                        </tr>
                                        <tr>
                                            <th colspan="6" class="text-right">Total</th>
                                            <th class="text-right">{{ (order.total*1).toFixed(2) }}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>

</template>

<script>
	export default {
		name: 'orders.Index',
		data(){
            return{
				order:{},
                errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
                let uri = {'uri':'showOrder/'+to.params.order_id};
                vm.$store.dispatch('get',uri)
                .then(function (response) {
                    vm.order = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error',error.response.data.message);
                });
			});
        },
		methods:{
            destroy() {
                let vm = this;
                if(confirm('Do you want to delete this entry ?')) {
                    let loader = vm.$loading.show();
                    let uri = {'uri':'deleteOrder/'+vm.order.order_id};
                    vm.$store.dispatch('delete',uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch('success','Order is successfully deleted');
                        vm.$router.push('/dashboard')
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch('error',error.response.data.message);
                    });
                }
            },
		}
	}
</script>
