<template>
    <footer class="main-footer">
        <!-- To the right -->
        <div class="float-right d-none d-sm-inline">
            Powered By Aeriesys Tech
        </div>
        <!-- Default to the left -->
        <strong>Copyright &copy; 2021 <a href="#">Softmusk Info Private Limited</a>.</strong> All rights reserved.
    </footer>

</template>