<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<i class="fa fa-home"></i>
					Dashboard
				</li>
				<li class="breadcrumb-item active">Orders</li>
				<li class="breadcrumb-right">
					<button @click="syncOrders()" class="btn btn-sm btn-success">
						<i class="fa fa-sync"></i> Sync Orders
					</button>
				</li>
			</ol>
		</nav>
		<div class="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-sm-12">
						<div class="card">
							<div class="card-header">
								<strong>Orders</strong>
							</div>
							<div class="card-body">
								<div class="form-group">
									<input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="index">
								</div>
								<table class="table table-responsive-sm table-bordered table-striped table-sm">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th>SO No</th>
											<th>Order No</th>
											<th>Order Date</th>
											<th>Customer Name</th>
											<th>Ordered By</th>
											<th class="text-center">Status</th>
											<th class="text-right">Total</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(order,key) in orders" :key="key" class="cursor" @click="showOrder(order)">
											<td class="text-center">{{ meta.from+key }}</td>
											<td>{{ order.order_id }}</td>
											<td>{{ order.order_no }}</td>
											<td>{{ order.order_date }}</td>
											<td>{{ order.customer.customer_name }}</td>
											<td>{{ order.user.name }}</td>
											<td class="text-center">
												<span v-if="order.status=='Pending'" class="badge badge-warning">{{ order.status }}</span>
												<span v-else class="badge badge-success">{{ order.status }}</span>
											</td>
											<td class="text-right text-danger text-bold">{{ (order.total*1).toFixed(2) }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="card-footer">
								<div class="float-right">
									<Pagination
										:maxPage="meta.maxPage"
										:totalPages="meta.lastPage"
										:currentPage="meta.currentPage"
										@pagechanged="onPageChange"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination.vue';
	export default {
		name: 'orders.Index',
		components: { Pagination },
		data(){
			return{
				meta: {
					search:'',
					order_by:'desc',
					keyword:'order_no',
					per_page:10,
					totalRows:0,
					currentPage:1,
					lastPage:1,
					from: 1,
					maxPage:1,
				},
				timer: '',
				orders:[],
				errors:[]
			}
		},
		mounted() {
			let vm = this;
			vm.index();
			vm.runTimer();
		},
		methods:{

			runTimer() {
				let vm = this;
				vm.timer = setInterval(function(){
					vm.meta.currentPage = 1;
					vm.index();
				}.bind(), 60000);
			},

			index() {
				let vm = this;
				let uri = 'orders?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&keyword='+vm.meta.keyword+'&per_page='+vm.meta.per_page;
				vm.$store.dispatch('get',{'uri':uri})
				.then(function (response) {
					vm.orders = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			showOrder(order){
				this.$router.push('/orders/'+order.order_id)
			},

			syncOrders() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'syncOrders','data':vm.meta};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Orders has been updated successfully');
					vm.index()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			onPageChange(page) {
				this.meta.currentPage = page;
				this.index();
			}
		}
	}
</script>
