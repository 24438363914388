import { createStore } from 'vuex'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import axios from 'axios';

export default createStore({
	state: {
		apiUrl: "http://64.227.129.184/api/",
		assetUrl: "http://64.227.129.184/storage/",

		authenticated:false,
		user:null,
	},
	
	mutations: {
		setAuthentication(state, data){
			state.authenticated = data;
		},
		setUser(state, user){
			state.user = user;
		}
	},

	getters: {
		authenticated(state){
			return state.authenticated;
		},
		user(state){
			return state.user;
		},
		apiUrl(state){
			return state.apiUrl;
		},
		assetUrl(state){
			return state.assetUrl;
		}
	},

	actions: {
		async setUser(context,payload) {
			localStorage.setItem("user_id", payload.user_id);
			localStorage.setItem("name", payload.name);
			localStorage.setItem("email", payload.email);
			localStorage.setItem("mobile_no", payload.mobile_no);
			localStorage.setItem("role", payload.role);
			localStorage.setItem("token", payload.token);
			await context.commit('setUser',payload);
			await context.commit('setAuthentication',true);
		},

		async autoLogin(context) {
			const user_id = localStorage.getItem('user_id');
			const name = localStorage.getItem('name');
			const email = localStorage.getItem('email');
			const mobile_no = localStorage.getItem('mobile_no');
			const role = localStorage.getItem('role');
			const token = localStorage.getItem('token');
			if (user_id && token) {
				await context.commit('setUser', {
					user_id: user_id,
					name: name,
					email: email,
					mobile_no: mobile_no,
					role: role,
					token: token,
				});
				await context.commit('setAuthentication',true);
			}
		},

		async logout(context) {
			localStorage.removeItem('user_id');
			localStorage.removeItem('name');
			localStorage.removeItem('email');
			localStorage.removeItem('mobile_no');
			localStorage.removeItem('role');
			localStorage.removeItem('token');
			await context.commit('setUser', null);
			await context.commit('setAuthentication',false);
		},

		auth(context,payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl+payload.uri,payload.data)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		get(context,payload) {
			return new Promise((resolve, reject) => {
				axios.get(this.state.apiUrl+payload.uri, {
                    headers: {
                        'Authorization': 'Bearer' + ' ' + context.getters.user.token
                    }
                }).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		post(context,payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl+payload.uri,payload.data, {
                    headers: {
                        'Authorization': 'Bearer' + ' ' + context.getters.user.token
                    }
                }).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		patch(context,payload) {
			return new Promise((resolve, reject) => {
				axios.patch(this.state.apiUrl+payload.uri,payload.data, {
                    headers: {
                        'Authorization': 'Bearer' + ' ' + context.getters.user.token
                    }
                }).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		delete(context,payload) {
			return new Promise((resolve, reject) => {
				axios.delete(this.state.apiUrl+payload.uri, {
                    headers: {
                        'Authorization': 'Bearer' + ' ' + context.getters.user.token
                    }
                }).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		upload(context,payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl+payload.uri,payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
						'Authorization': 'Bearer' + ' ' + context.getters.user.token
                    }
                }).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		async error(context,description) {
			await createToast({
				title: 'Error',
				description: description || "The given data was invalid."
			},
			{
				showIcon: true,
				hideProgressBar: true,
				type: 'danger',
				position: 'top-right'
			})
		},

		async success(context,description) {
			await createToast({
				title: 'Success',
				description: description || "Data is successfuly subbmited."
			},
			{
				showIcon: true,
				hideProgressBar: true,
				type: 'success',
				position: 'top-right'
			})
		}
	}
})
